<template>
  <div id="virus19comeack">
   
    <Card style="width:96%;margin:20px 2% 0 2%;">
      <Row type="flex" justify="space-between">
        <Col span="6" style="text-align:center;font-size:16px;font-weight:bold;" v-for="(item,index) in chartData" :key="index">
        {{item.columns[1]}} <pie style="margin-top:-20px;" :data="item" :settings="settingsparam" :legend="{show:false}" ></pie>
        </Col>
             </Row>
          </Card>
  </div>
</template>
<script>
import pie from "v-charts/lib/pie.common";
export default {
  name: "virus19comeack",
  components: {
    pie,
  },
  data() {
    return {
      chartData:  [ 
        {columns: ['地区', '出行计划累计调查人数'],
                  rows:[
                    {'地区': '海珠区', '出行计划累计调查人数': 20057.34},
                    {'地区': '花都区', '出行计划累计调查人数': 15477.48},
                    {'地区': '从化区', '出行计划累计调查人数': 31686.1},
                    {'地区': '白云区', '出行计划累计调查人数': 6992.6},
                    {'地区': '增城区', '出行计划累计调查人数': 44045.49},
                    {'地区': '越秀区', '出行计划累计调查人数': 40689.64},
                    {'地区': '荔湾区', '出行计划累计调查人数': 55204.26},
                    {'地区': '天河区', '出行计划累计调查人数': 21900.9},
                    {'地区': '黄埔区', '出行计划累计调查人数': 4918.26},
                    {'地区': '番禺区', '出行计划累计调查人数': 5881.84},
                    {'地区': '南沙区', '出行计划累计调查人数': 4178.01},
                ]},{columns: ['地区', '出行计划累计调查人数'],
                  rows:[
                    {'地区': '海珠区', '出行计划累计调查人数': 20057.34},
                    {'地区': '花都区', '出行计划累计调查人数': 15477.48},
                    {'地区': '从化区', '出行计划累计调查人数': 31686.1},
                    {'地区': '白云区', '出行计划累计调查人数': 6992.6},
                    {'地区': '增城区', '出行计划累计调查人数': 44045.49},
                    {'地区': '越秀区', '出行计划累计调查人数': 40689.64},
                    {'地区': '荔湾区', '出行计划累计调查人数': 55204.26},
                    {'地区': '天河区', '出行计划累计调查人数': 21900.9},
                    {'地区': '黄埔区', '出行计划累计调查人数': 4918.26},
                    {'地区': '番禺区', '出行计划累计调查人数': 5881.84},
                    {'地区': '南沙区', '出行计划累计调查人数': 4178.01},
                ]},{columns: ['地区', '出行计划累计调查人数'],
                  rows:[
                    {'地区': '海珠区', '出行计划累计调查人数': 20057.34},
                    {'地区': '花都区', '出行计划累计调查人数': 15477.48},
                    {'地区': '从化区', '出行计划累计调查人数': 31686.1},
                    {'地区': '白云区', '出行计划累计调查人数': 6992.6},
                    {'地区': '增城区', '出行计划累计调查人数': 44045.49},
                    {'地区': '越秀区', '出行计划累计调查人数': 40689.64},
                    {'地区': '荔湾区', '出行计划累计调查人数': 55204.26},
                    {'地区': '天河区', '出行计划累计调查人数': 21900.9},
                    {'地区': '黄埔区', '出行计划累计调查人数': 4918.26},
                    {'地区': '番禺区', '出行计划累计调查人数': 5881.84},
                    {'地区': '南沙区', '出行计划累计调查人数': 4178.01},
                ]},{columns: ['地区', '出行计划累计调查人数'],
                  rows:[
                    {'地区': '海珠区', '出行计划累计调查人数': 20057.34},
                    {'地区': '花都区', '出行计划累计调查人数': 15477.48},
                    {'地区': '从化区', '出行计划累计调查人数': 31686.1},
                    {'地区': '白云区', '出行计划累计调查人数': 6992.6},
                    {'地区': '增城区', '出行计划累计调查人数': 44045.49},
                    {'地区': '越秀区', '出行计划累计调查人数': 40689.64},
                    {'地区': '荔湾区', '出行计划累计调查人数': 55204.26},
                    {'地区': '天河区', '出行计划累计调查人数': 21900.9},
                    {'地区': '黄埔区', '出行计划累计调查人数': 4918.26},
                    {'地区': '番禺区', '出行计划累计调查人数': 5881.84},
                    {'地区': '南沙区', '出行计划累计调查人数': 4178.01},
                ]}
      ],
      settingsparam: { label: { show: true }, stack: { xxx: [] } },
    };
  },
  created() {
   
  },
  mounted() {
  

  },
  watch: {},
  methods: {

  },
};
</script>

<style lang="less" scoped>

</style>
