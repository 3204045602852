<template>
  <div id="statisticsvirus19">
    <!--内容区域布局-->
    <LiefengContent>
      <!--功能名称，定义则显示-->
      <template v-slot:title>疫情上报统计</template>
       <!--左则工具条-->
      <template v-slot:toolsbarLeft>
        <div class="tooldiv">数据更新至2021.02.03 14:30</div> 
      </template>
       <!--右则工具条-->
      <template v-slot:toolsbarRight>
        <div class="tooldiv">市：</div>
    <Select filterable placeholder="广州市" style="width:100px">
      <Option value="广州市" selected>广州市</Option>
    </Select>
    <div class="tooldiv">区：</div>
    <Select v-model="selectArea" filterable :max-tag-count="2" clearable  @on-change="loadAreaList" placeholder="请选择区" style="width:120px">
        <Option v-for="item in areaList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <div class="tooldiv">街道：</div>
    <Select v-model="selectStree" filterable clearable :max-tag-count="2" @on-change="loadTotalData" style="width:180px" placeholder="请选择街道" :disabled="disabledStree">
        <Option v-for="item in streeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
      </template>
       <!--内容区域-->
      <template v-slot:contentArea>
        <div id="totaltab" style="display:flex;align-items:stretch;height:100px;justify-content: space-between;margin:20px 2%;">
         <Card  class="cardsty">
        <div>
            <h1>{{totalData.menuTotal}}</h1>
            <p>填报登记人次</p>
        </div>
         </Card>
             <Card  class="cardsty">
        <div>
            <h1>{{totalData.todayAdd}}</h1>
            <p>出行计划调查人数</p>
        </div>
         </Card>
             <Card class="cardsty">
        <div>
            <h1>{{totalData.weekAdd}}</h1>
            <p>离穗返穗登记人数</p>
        </div>
         </Card>
             <Card class="cardsty">
        <div>
            <h1>{{totalData.monthAdd}}</h1>
            <p>亲友来访登记人数</p>
        </div>
         </Card>
          
        </div>
    <div style="display:flex;align-items:stretch;justify-content:space-between;margin:20px 2%;">
      <div style="font-size:16px;float:left;">应填报人口：约1530万，实际填报人口占比：58.902%</div> 
        
    </div>

         <Card style="width:96%;margin:20px 2% 0 2%;">
                <div id="menuDIV" slot="title">             
                  <Button size="large" @click="goto('travel')" style="padding:0 30px;"  :type="type=='travel'?'success':'default'">出行计划调查填报情况</Button>
                  <Button size="large" @click="goto('comeback')" style="padding:0 30px;"  :type="type=='comeback'?'success':'default'">离穗返穗登记填报情况</Button>
                  <Button size="large" @click="goto('visit')" style="padding:0 30px;"  :type="type=='visit'?'success':'default'">亲友来访登记填情况</Button>
                </div>  
                  <div id="container" style="height:700px;"></div>
          </Card>

    <Card style="width:96%;margin:20px 2% 0 2%;">
          
                   <VeBar :data="chartData" :settings="settingsparam"></VeBar>
                 <p style="text-align:right;">
                   <Button type="default"  icon="md-download" @click="exportData">导出表格</Button>
                    </p>
                 <p>
                  <Table ref="lftable"  border :columns="talbeColumns" :data="tableData" show-summary>
                </Table>
                </p>
               
          </Card>


      <travel v-if="type=='travel'"></travel>
      <comeback v-if="type=='comeback'"></comeback>
      <visit v-if="type=='visit'"></visit>


      </template>
    </LiefengContent>

  </div>
</template>
<script>
//@route('/statisticsvirus19')
import * as echarts from 'echarts';
import LiefengContent from "@/components/LiefengContent.vue";
import VeBar from "v-charts/lib/bar.common";
import LiefengTable from "@/components/LiefengTable.vue";
import travel from "./childrens/travel.vue";
import comeback from "./childrens/comeback.vue";
import visit from "./childrens/visit.vue";
export default {
  name: "statisticsvirus19",
  components: {
    LiefengContent,
    VeBar,
    LiefengTable,
    travel,visit,comeback
  },
  data() {
    return {
      type:"travel",
      chartData: {columns: ['地区', '出行计划累计调查人数'],
                  rows:[
                    {'地区': '海珠区', '出行计划累计调查人数': 20057.34},
                    {'地区': '花都区', '出行计划累计调查人数': 15477.48},
                    {'地区': '从化区', '出行计划累计调查人数': 31686.1},
                    {'地区': '白云区', '出行计划累计调查人数': 6992.6},
                    {'地区': '增城区', '出行计划累计调查人数': 44045.49},
                    {'地区': '越秀区', '出行计划累计调查人数': 40689.64},
                    {'地区': '荔湾区', '出行计划累计调查人数': 55204.26},
                    {'地区': '天河区', '出行计划累计调查人数': 21900.9},
                    {'地区': '黄埔区', '出行计划累计调查人数': 4918.26},
                    {'地区': '番禺区', '出行计划累计调查人数': 5881.84},
                    {'地区': '南沙区', '出行计划累计调查人数': 4178.01},
                ]},
      talbeColumns: [{ title: "辖区", key: "title" },{ title: "出行计划累计调查人数", key: "value1", minWidth: 120 },{ title: "昨日填报人数", key: "value2", minWidth: 120 }],
      tableData: [ {title: '海珠区', value1: 20057.34, value2: 2017.34},
                    {title: '花都区', value1: 15477.48, value2: 2017.34},
                    {title: '从化区', value1: 31686.1, value2: 2017.34},
                    {title: '白云区', value1: 6992.6, value2: 2017.34},
                    {title: '增城区', value1: 44045.49, value2: 2017.34},
                    {title: '越秀区', value1: 40689.64, value2: 2017.34},
                    {title: '荔湾区', value1: 55204.26, value2: 2017.34},
                    {title: '天河区', value1: 21900.9, value2: 2017.34},
                    {title: '黄埔区', value1: 4918.26, value2: 2017.34},
                    {title: '番禺区', value1: 5881.84, value2: 2017.34},
                    {title: '南沙区', value1: 4178.01, value2: 2017.34},],
      selectdate: [],

      //区列表
      areaCode: "",
      areaList: [],
      selectArea: [],

      //街道列表
      streeList: [],
      streeCode: "",
      selectStree: [],
      disabledStree: false,
      //社区列表
      communitylist: [],
      selectedcommunity: [],
      disabledCommunity: false,
      timetype: "day",
      title: "",
      totalData: {},
      settingsparam: { label: { show: true }, stack: { xxx: [] } },



      myChart:null,
      option:null,
    };
  },
  created() {
    this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
      //加载城市列表
      adminType: parent.vue.loginInfo.userinfo.adminType || '', //超级管理员标识
      custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: "4401",  //组织机构编码,目前只有广州市，先写死
      orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode, 
    })
      .then((res) => {
        if (res.code == 200) {
          this.areaList = res.dataList.map((item, index) => {
            return {
              value: item.orgCode,
            label: item.orgName,
            };
          });
        } else {
          this.$Message.error("获取区列表失败");
        }
      })
      .catch((err) => {});
  },
  mounted() {
    var that=this;
  //  this.loadTotalData();

    this.$(this.$("#totaltab").parent()).scroll(function(){
      that.$("#menuDIV").removeClass("menuDIV");
      if(that.$(this).scrollTop()>=200){
        that.$("#menuDIV").addClass("menuDIV");
      }
    });



//////////////////////////////////////////////////////

var dom = document.getElementById("container");
this.myChart = echarts.init(dom);
var app = {};



this.myChart.showLoading();

//this.createMap(this.geoJson([{name:'啥啥街'},{name:'咩街'},{name:'什么街'},{name:'有条街'},{name:'一条街'}]));
this.$.get('/testjson/statistics/map.json',this.createMap); 
if (this.option && typeof this.option === 'object') {
		this.myChart.on('click', this.eConsole);
    this.myChart.setOption(this.option);
}

/////////////////////////////////////////////////////




  },
  watch: {},
  methods: {
    goto(type){
     this.type=type;
     this.$router.push({path:"/statisticsvirus19?type="+this.type});
     this.$(this.$("#totaltab").parent()).scrollTop(190);
     //重置数据
     
    },
///////////////////////////////////////////////////////
geoJson(areaList){
	var mdata=[];
	var lng=112.926419
	var lat=23.351278;
		areaList.map((item,index)=>{
				var ll=[
				[lng,lat],
				[lng+1,lat],
				[lng+1,lat-0.1],
				[lng, lat-0.1]
				];
				lat+=0.1;
	
				var m={ type: "Feature", properties: { name: item.name, ID_0: 10000+index, ID_1: index, ISO: "LF" }, geometry: { type: "MultiPolygon", 
						coordinates: [ [ ll ] ] } };
						mdata.push(m);
						
		});
	var json={type: "FeatureCollection",
						features: mdata};
	return json;
},
createMap(geoJson) {

    this.myChart.hideLoading();

    echarts.registerMap('LF', geoJson);

    this.myChart.setOption(this.option = {
        title: {
            text: '广州市各区亲友来访登记情况',
            subtext: '',
            sublink: '',
            left:'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b}<br/>{c}'
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                dataView: {readOnly: false},
                restore: {},
                saveAsImage: {}
            }
        },
        visualMap: {
            min: 800,
            max: 50000,
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
                color: ['lightskyblue', 'yellow', 'orangered']
            }
        },
        series: [
            {
                name: '广州市各区亲友来访登记情况',
                type: 'map',
                mapType: 'LF', // 自定义扩展图表类型
                label: {
                    show: true,
                    fontSize:14,
                },
            		selectedMode:false,
            		emphasis:{itemStyle:{areaColor:"lightgreen"}},
            		select:{itemStyle:{color:"lightgreen"}},
                data: [
                    {name: '海珠区', value: 20057.34},
                    {name: '花都区', value: 15477.48},
                    {name: '从化区', value: 31686.1},
                    {name: '白云区', value: 6992.6},
                    {name: '增城区', value: 44045.49},
                    {name: '越秀区', value: 40689.64},
                    {name: '荔湾区', value: 55204.26},
                    {name: '天河区', value: 21900.9},
                    {name: '黄埔区', value: 4918.26},
                    {name: '番禺区', value: 5881.84},
                    {name: '南沙区', value: 4178.01},

                    {name:'啥啥街', value: 29178.01},{name:'咩街', value: 13178.01},{name:'什么街', value: 2178.01},{name:'有条街', value: 98178.01},{name:'一条街', value: 5178.01}
                    
                ]
            }
        ]
    });
},

eConsole(param) {
    console.log(param);
},



/////////////////////////////////////////////////////













    //区选中,获取街道列表
    loadAreaList(value) {
      if (value.length > 1) {
        //多选区时，禁用街道和社区
        this.streeCode = [];
        this.selectStree = "";
        this.communityCode = [];
        this.communitylist = [];
        this.streeList = [];
        this.selectedcommunity = "";
        this.disabledStree = true;
        this.disabledCommunity = true;
        return;
      }else if(value.length == 0) {
        this.streeCode = [];
        this.selectStree = "";
        this.communityCode = [];
        this.communitylist = [];
        this.streeList = [];
        this.selectedcommunity = "";
        return;
      }
      this.disabledStree = false;
      this.disabledCommunity = false;
      this.areaCode = value.join(",");
      this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
        adminType:  parent.vue.loginInfo.userinfo.adminType || '', //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.areaCode,
        orgCodeSelf:  parent.vue.loginInfo.userinfo.orgCode  
      }).then((res) => {
        if (res.code == 200) {
          this.streeList = res.dataList.map((item, index) => {
            return {
              value: item.orgCode,
            label: item.orgName,
            };
          });
        }
      });
    },
    //街道选中，获取社区列表
    loadStreeList(value) {
      if (value.length > 1 || this.selectArea.length > 1) {
        //禁用社区
        this.communityCode = [];
        this.communitylist = [];
        this.selectedcommunity = "";
        this.disabledCommunity = true;
        return;
      }else if(value.length == 0) {
        this.communityCode = [];
        this.communitylist = [];
        this.selectedcommunity = "";
        return;
      }
      this.disabledCommunity = false;
      this.streeCode = value.join(",");
      this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
        adminType:  parent.vue.loginInfo.userinfo.adminType || '', //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
       orgCode: this.streeCode ,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode  
      }).then((res) => {
        if (res.code == 200) {
          this.communitylist = res.dataList.map((item, index) => {
            return {
              value: item.orgCode,
            label: item.orgName,
            };
          });
        }
      });
    },
    //选中社区
    loadCommunityList(value) {
      this.communityCode = value.join(",");
    },

    loadTotalData() {
      //加载全局汇总
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });
      let codes = "";
      if (this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(",");
      } else if (
        this.selectStree.length > 1 ||
        this.selectedcommunity.length == 0
      ) {
        codes = this.selectStree.join(",");
      } else {
        codes = this.selectedcommunity.join(",");
      }
      this.$get("/statistic/api/symanage/pc/statColumn/statMenuColumnNotOrg", {
        codes: codes,
        oemCode: parent.vue.oemInfo.oemCode,
      })
        .then((res) => {
          if (res && res.data) {
            this.totalData = res.data;
          }
          this.$Message.destroy();
          this.loadPublishData();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    loadPublishData() {
      //读取栏目数
      var starttime = "";
      var endtime = "";
      var now = new Date();
      switch (this.timetype) {
        case "all":
          starttime = "2000-01-01 00:00:00.00";
          endtime = this.$core.formatDate(now, "yyyy-MM-dd 23:59:59.59");
          this.title = "全部";
          break;
        case "day":
          let yesterday = now.getTime() - 1000*60*60*24;
         starttime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 00:00:00.00");
          endtime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 23:59:59.59");
          this.title = "昨天";
          break;
        case "week":
          starttime = this.$core.formatDate(
            new Date(
            now.getTime() - 1000*60*60*24 * 7),
            "yyyy-MM-dd 00:00:00.00"
          );
          endtime = this.$core.formatDate(
            new Date(
              now.getTime() - 1000*60*60*24
            ),
            "yyyy-MM-dd 23:59:59.59"
          );
          this.title = "前七天";
          break;
        case "month":
           starttime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24 * 30),
            "yyyy-MM-dd 00:00:00.00"
          );
          endtime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24),
            "yyyy-MM-dd 23:59:59.59"
          );
          this.title = "前30天";
          break;
        default:
          if (
            this.selectdate.length > 1 &&
            this.selectdate[0] != "" &&
            this.selectdate[1] != ""
          ) {
            starttime = this.$core.formatDate(
              this.selectdate[0],
              "yyyy-MM-dd 00:00:00.00"
            );
            endtime = this.$core.formatDate(
              this.selectdate[1],
              "yyyy-MM-dd 23:59:59.59"
            );
            this.title =
              starttime.substring(0, 10) + "~" + endtime.substring(0, 10);
          }
          break;
      }
      if (starttime == "" || endtime == "") {
        this.$Message.error({ content: "请选择时间段", background: true });
        return;
      }
      this.chartData = {};
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });
     let codes = '';
      if(this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(',');
      }else if(this.selectStree.length > 1 || this.selectedcommunity.length == 0){
        codes = this.selectStree.join(',');
      }else {
        codes = this.selectedcommunity.join(',');
      }
      this.$get(
        "/statistic/api/symanage/pc/statColumn/statMenuTypeColumnNotOrg",
        {
        codes: codes,
        startTime: starttime,
        endTime: endtime,
        oemCode: parent.vue.oemInfo.oemCode
        }
      )
        .then((res) => {
          if (res && res.data) {
            this.chartData = res.data;
            this.settingsparam.stack.xxx = this.chartData.columns;
            this.createTableData(this.chartData.rows || []);
          }
          this.$Message.destroy();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    createTableData(chartData) {
      //将图表数据转化为表格数据
      this.talbeColumns = [{ title: "分类", key: "title", minWidth: 120 }];
      this.tableData = [];
      //console.log(chartData);
      var data = [];
      chartData.forEach((item, index) => {
        var i = 0;
        for (var key in item) {
          if (Number(item[key]) || item[key] == 0) {
            var ii = i++;
            if (!data[ii]) data.push({ title: key });
            data[ii]["value" + index] = item[key];
          } else {
            this.talbeColumns.push({
              title: item[key],
              key: "value" + index,
              minWidth: item[key].length * 20 + 30,
            });
          }
        }
      });
      this.tableData = data;
    },
    exportData() {
      this.$refs.lftable.exportCsv({
        filename: this.title + "分类栏目数",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cardsty {
  min-width: 250px;
  width: 20%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  font-size: 16px;
  margin-top: 20px;
}
#menuDIV{
display:flex;align-items:center;justify-content:space-between;
}
.menuDIV{
  position: fixed;
  top:50px;
  left:4%;
  right: 4%;
  width:92%;
  z-index: 10000;
  background: #fff;
  height:60px;
  padding:0 10px;
  border-bottom:1px solid #eee;
}


</style>
